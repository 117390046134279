@charset "UTF-8";
/* Reset */
// prettier-ignore
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

address,
em,
strong,
th {
  font-style: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 5em;
}

hr,
legend {
  display: none;
}

img,
fieldset {
  border: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

/* Fonts */
body {
  font-size: 100%; /* IE */
  font-family: 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', Osaka, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', verdana, 'MS PGothic', Sans-Serif;
}

html > body {
  font-size: 12px; /* Except IE */
}

/* clearfix */
.clearfix {
  zoom: 1;
  :after {
    content: '.';
    display: block;
    visibility: hidden;
    height: 0;
    clear: both;
  }
}
